import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import {fixedValues, clearString} from 'util/Util';
import SearchContext from 'context/SearchContext';


const EMenu = () => {
    const [searchParams] = useSearchParams();
    const {setOpenMenu, temas} = useContext(SearchContext);

    const [tema, setTema] = useState(searchParams.get('tema')?.trim());
    const [query, setQuery] = useState(searchParams.get('query')?.trim());

    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setTema(searchParams.get('tema')?.trim());
    }, [searchParams]);

    const selectMenu = (subject) => {
        (document.getElementById('filter-tema') || {}).value = subject;
        (document.getElementById('search-prompt') || {}).value = '';
        setOpenMenu(false)
        document.getElementById('search-box')?.submit();
    }

    return (
        <div className="emenu">
            <div className="menu-title">Temas</div>
            {fixedValues.dc_subject.map((fv, i) => {
                let selectTema = clearString(tema) === clearString(fv) && !query;
                let present = temas.find(t => clearString(fv) === clearString(t.key))
                return ( <button key={'menu-item-' + i}
                    className={`menu-item ${ selectTema ? 'ico-f3e selected' : ''}`}
                    onClick={() => selectMenu(fv)}
                    disabled={!present}
                    data-text={fv}
                >
                    <span>{fv}</span>
                    <span>{present?.doc_count}</span>
                </button> )
            }) }
        </div>
    );
};

export default EMenu
