
const jQuery = require('jquery');
const S = require('string');


/* --- words  plugin ---- */
(function( $ ) {

    $.fn.words = function(data) {
        if(typeof data === 'string') {
            return;
        }

        this.each(function() {
            var $this = $(this);
            $this.data(configName, $.extend({}, defaults, data));
            $this.addClass(info.plugin);
            init.call($this);
        });
        return this;
    };


    function init() {
        var $this = this;
        var config = $this.data(configName);
        if (config.prepare) config.prepare($this);
        _processElement.call($this, $this);
    }

    function _processElement($elem) {
        var $this = this;
        var config = $this.data(configName);

        let currStr;
        let currWordArray;
        let currW = '';
        let $w;

        let $dome
        let $str;
        $elem.contents().each(function (index, e) {
            $dome = $(e);


            if(e.nodeType === Node.TEXT_NODE) {
                // si es nodo texto
                currStr = S($dome.text()).trim().collapseWhitespace();

                // Not empty
                if (!currStr.isEmpty()) {
                    currWordArray = currStr.split(' ');
                    $str = $('<div/>');

                    // is TEXT
                    // Append every word
                    for (let i = 0; i < currWordArray.length; i++) {
                        // separate init or final punctuation
                        currW = S(currWordArray[i]);

                        if (currW.isAlphaNumeric()) { // es una palabra normal
                            $w = _wrapWord.call($this, currW.s, true);
                            $str.append($w);
                        } else {
                            if (currW.s.length === 1) {
                                if (config.signs.indexOf(currW.s) < 0) {
                                    lastRtrim($str);
                                }

                                $w = _wrapWord.call($this, currW.s, _signWSpace.call($this, currW.s)).addClass(config.punctClass);
                                $str.append($w);
                            } else {

                                let initPunctData = _rmInitPunct.call($this, currW.s);
                                let endPunctData = _rmEndPunct.call($this, initPunctData.word);
                                let wordNotPunct = endPunctData.word;

                                for (let j = 0; j < initPunctData.signs.length; j++) {
                                    $w = _wrapWord.call($this, initPunctData.signs[j], _signWSpace.call($this, initPunctData.signs[j])).addClass(config.punctClass);
                                    $str.append($w);
                                }

                                if (wordNotPunct.length > 0) {
                                    $w = _wrapWord.call($this, wordNotPunct, true)
                                    $str.append($w);
                                }

                                for (let j = 0; j < endPunctData.signs.length; j++) {
                                    lastRtrim($str);
                                    $w = _wrapWord.call($this, endPunctData.signs[j], _signWSpace.call($this, endPunctData.signs[j])).addClass(config.punctClass)
                                    $str.append($w);
                                }

                            }
                        } // if
                    } // for

                    $dome.replaceWith($str.children());
                }// !currStr.isEmpty
            } else if ($dome.hasClass(config.wordClass)) { // is WORD: no hacemos nada

            } else { // is ELEMENT
                _processElement.call($this, $dome);
            }
        });
    }

    function _rmInitPunct(word) {
        // var $this = this;
        // var config = $this.data(configName);

        let returnData = {'signs': [], 'word': ''};
        let sword = S(word).trim().collapseWhitespace();

        while (sword.s.length > 0 && !S(sword.s.charAt(0)).isAlphaNumeric()) {
            returnData.signs.push(sword.left(1).s);
            sword = S(sword.s.substring(1));
        }

        returnData.word = sword.s;

        return returnData;
    }

    function _rmEndPunct(word) {
        // var $this = this;
        // var config = $this.data(configName);

        let returnData = {'signs': [], 'word': ''};
        let sword = S(word).trim().collapseWhitespace();

        while (sword.s.length > 0 && !S(sword.s.charAt(sword.s.length-1)).isAlphaNumeric()) {
            returnData.signs.unshift(sword.right(1).s);
            sword = S(sword.s.substring(0, sword.s.length-1));
        }

        returnData.word = sword.s;

        return returnData;
    }

    function lastRtrim($str) {
        var $last = $str.children().last();
        if ($last.html()) $last.html(S($last.html()).trimRight().s);
        return $last;
    }

    function _signWSpace(sign) {
        var $this = this;
        var config = $this.data(configName);

        if (config.signs.indexOf(sign) >= 0) {
            return false;
        }

        return true;
    }

    function _wrapWord(word, withSpace) {
        var $this = this;
        var config = $this.data(configName);

        let space = '';

        if (withSpace != null && withSpace === true) {
            space = ' ';
        }

        let $w = $('<' + config.wrapElem + '/>', { 'class': config.wordClass })
            // .attr(config.wattr, word)
            .attr(config.wattr, config.lastIdx++)
            .append(word + space);
        return $w;
    }

    $.fn.words.info = {
        plugin: 'words'
        , name: 'Separa palabras'
        , prefix: 'words-'
        , version: '1.0'
        , events: { }
        , methods: { }

    };

    $.fn.words.defaults = {
        wordClass: 'js-word'
        , punctClass: 'js-punct'
        , wattr: 'data-word'
        , wrapElem: 'span'
        , signs: ['¿', '¡', '(', '“']
        , indexPunct: false
        , lastIdx: 0
    };

    // Para acceso rápido, inmutables
    var info = $.fn.words.info;
    var defaults = $.fn.words.defaults;
    var configName = info.plugin + '-config';

}( jQuery ));
