import { useContext, useEffect, useState, useCallback } from "react";
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';


import SearchContext from 'context/SearchContext';

const basename = process.env.REACT_APP_BASENAME;

const SearchBox = () => {
    const {autocomplete} = useContext(SearchContext);
    const [searchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get('query')?.trim() || '');


    const [suggestions, setSuggestions] = useState([]);


    // https://stackoverflow.com/a/66652147
    const [inputValue, setInputValue] = useState("");
    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            setSuggestions([]);
            autocomplete(text).then(callback);
        }, 250),
        []
    );

    useEffect(() => {
        getOptionsDelayed(inputValue, (filteredOptions) => {
            setSuggestions(filteredOptions);
        });
    }, [inputValue, getOptionsDelayed]);



    const hChange = (ev, val, reason) => {
        if (ev.type === 'click') {
            document.getElementById('search-prompt').value = val
            document.getElementById('search-box')?.submit()
        }
    }


    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setQuery(searchParams.get('query')?.trim() || '');
    }, [searchParams]);

    const hKeyPress = (ev) => {
        switch (ev.key) {
            case 'Enter':
                document.getElementById('search-box')?.submit()
                break;
            default:

        }
    }

    useEffect(() => {
        document.getElementById('search-prompt')?.addEventListener('keydown', hKeyPress);
        return () => document.getElementById('search-prompt')?.removeEventListener('keydown', hKeyPress);
    }, []);

    return (
        <form method="GET" action={`${basename ? '/' + basename : ''}/`} id="search-box" className="search-box">

            <Autocomplete
                freeSolo
                disablePortal
                autoComplete
                forcePopupIcon={false}
                clearOnEscape={true}
                defaultValue={query}
                value={query}
                id='search-prompt'
                options={suggestions}
                noOptionsText="No se encontró ninguna sugerencia"
                onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                renderInput={(params) => <TextField {...params} name='query' placeholder="Buscar..."/>}
                filterOptions={(x) => x} // you will need to disable the built-in filtering
                onChange={hChange}
            />

            <input id="filter-tema" type="hidden" name="tema" defaultValue={searchParams.get('tema')} />
            <i className="search icon lupa" onClick={() => document.getElementById('search-box')?.submit()}></i>
        </form>
    );
};

export default SearchBox;
