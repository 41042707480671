import { Link, useNavigate } from 'react-router-dom';

export default function Error404() {
    const navigate = useNavigate();

    return (
        <main className="creditos">
            <Link onClick={() => navigate(-1)} className="liga-back ico-f1w"><span>Regresar</span></Link>
            <div className="contenido">
                <div>
                    <div className="titulo">Directorio</div>
                    
                    <div>
                        Universidad Nacional Autónoma de México <br/>
                        <span> Dr. Enrique Graue Wiechers </span> <br/>
                        Rector
                    </div>
                    <div>
                        <span> Dra. Patricia Dolores Dávila Aranda </span> <br/>
                        Secretaria de Desarrollo Institucional
                    </div>
                    <div>
                        <span> Dr. Héctor Benítez Pérez </span> <br/>
                        Dirección General de Cómputo y de <br/> Tecnologías de Información y Comunicación
                    </div>
                    <div>
                        <span> Dra. Marina Kriscautzky Laxague </span> <br/>
                        Directora de Innovación en Tecnologías <br/> para la Educación
                    </div>
                </div>

                <div>
                    <div className="titulo">Créditos</div>
                    <div>
                        <span>Marina Kriscautzky Laxague</span> <br/>
                        Coordinadora del proyecto
                    </div>
                    <div>
                        <span>Javier Rodrigo Díaz Espinoza</span> <br/>
                        Gestión del desarrollo web
                    </div>
                    <div>
                        <span>José Luis Villareal Benítez</span> <br/>
                        Análisis de requerimientos
                    </div>
                    <div>
                        <span>Pablo Enrique Zenil Rivas</span> <br/>
                        Desarrollo del portal web
                    </div>
                    <div>
                        <span>Mario Alberto Hernández Mayorga</span> <br/>
                        <span>Cristian Ricardo Ortega Ramírez</span> <br/>
                        Implementación de la arquitectura de la aplicación
                    </div>
                    <div>
                        <span>Itzel Hernández Serra</span> <br/>
                        Diseño de interfaz gráfica
                    </div>
                    <div>
                        <span>Antonio Eder Navarro Mendoza</span> <br/>
                        Diseño de isotipo e interfaz gráfica
                    </div>
                </div>
            </div>
        </main>
    )
}
