import { useState } from 'react';
import {Image} from "semantic-ui-react";
import { Link } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';


const ItemCard = ({item}) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const flip = () => {
        setIsFlipped(prev => !prev);
    }
    return (
        <div className="item-card" >
            <div className="tag"></div>
            <div className="thumb">
                <Link to={`item/${item.id}`} className="no-txt">
                    <span>{item.metadata.dc_title?.value}</span>
                    <Image src={item.iipthumb} alt="Imagen"/>
                </Link>
            </div>

            <div className="title">{item.metadata.dc_title?.value}</div>

            <div className={`metadata ${isFlipped ? 'flipped' : ''}`} >
                <span className="flip ico-notxt ico-flecha-l" onClick={flip}><span>Ver ficha</span></span>
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    <div>
                        <div className="key">Tema</div>
                        <div className="value">{item.metadata.dc_subject?.value}</div>
                        <div className="key">Nombre científico</div>
                        <div className="value">{item.metadata.dw_scientificname?.value}</div>
                        <div className="key">Nombre común</div>
                        <div className="value">{item.metadata.dw_vernacularname?.value}</div>
                        <div className="key val">{item.metadata.dc_subject_microscopetype?.value}</div>
                        {item.metadata.dc_subject_techniquetype?.value ?  
                            <>
                                <div className="key">{item.metadata.dc_subject_techniquetype?.name}</div>
                                <div className="value">{item.metadata.dc_subject_techniquetype?.value}</div>
                            </>
                            :
                            <>
                                {item.metadata.dc_subject_technique?.value ?
                                    <>
                                        <div className="key">{item.metadata.dc_subject_technique?.name}</div>
                                        <div className="value">{item.metadata.dc_subject_technique?.value}</div>
                                    </>
                                    :
                                    <div> </div>
                                }
                            </>
                        }
                        <div className="key">Autor</div>
                        <div className="value">{item.metadata.dc_creator?.value}</div>
                        <div className="value">{item.metadata.ncd_departament?.value}</div>
                        <div className="value">{item.metadata.ncd_parentinstitutionname?.value}</div>
                    </div>

                    <div className={`back ${!isFlipped ? 'hidden' : ''}`}>
                        <div className="key">Audiencia</div>
                        <div className="value">{item.metadata.dc_audience?.value}</div>
                        <div className="key">Nivel educativo</div>
                        <div className="value">{item.metadata.dc_audience_level?.value}</div>
                        {/* <div className="key">Licencia</div> */}
                        {/* <div className="value">{item.metadata.dc.value.rights.license }</div> */}
                        <div className="key">Derechos de acceso</div>
                        <div className="value">{item.metadata.dc_rights_accessrights?.value} </div>
                        <div className="key">Propietario de los derechos de autor</div>
                        <div className="value">{item.metadata.dc_rights_holder?.value} </div>
                        <div className="key">Colección</div>
                        <div className="value">{item.metadata.ncd_collectionName?.value} </div>
                        <div className="key">Responsable de la colección</div>
                        <div className="value">{item.metadata.ncd_maincollectionresponsible?.value}</div>
                    </div>
                </ReactCardFlip>
            </div>
        </div>
    )
};

export default ItemCard;
