import { useContext } from "react";
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SearchContext from 'context/SearchContext';

export default function LayoutBasic() {

    const {error} = useContext(SearchContext);
    return (
        <div className="page home">
            <Header/>
            <Outlet/>
            <Footer/>
            {error && <div className="error-msg"><h2>{error}</h2></div>}
        </div>
    )
}  
