import { Image } from 'semantic-ui-react';

const PaneFicha = ({item, active}) => {

    return (
        <div className={`pane ficha ${active ? 'active' : ''}`}>
            <div className="metadata datos pane-l">
                <div className="key">Tema</div>
                <div className="value">{ item.metadata.dc_subject?.value }</div>
                <div className="key">Nombre científico</div>
                <div className="value">{ item.metadata.dw_scientificname?.value }</div>
                <div className="key">Nombre común</div>
                <div className="value">{ item.metadata.dw_vernacularname?.value }</div>
                <div className="key val">{ item.metadata.dc_subject_microscopetype?.value }</div>
                {item.metadata.dc_subject_techniquetype?.value ?
                    <>
                        <div className="key">{item.metadata.dc_subject_techniquetype?.name}</div>
                        <div className="value">{item.metadata.dc_subject_techniquetype?.value}</div>
                    </>
                    :
                    <>
                        {item.metadata.dc_subject_technique?.value ?
                            <>
                                <div className="key">{item.metadata.dc_subject_technique?.name}</div>
                                <div className="value">{item.metadata.dc_subject_technique?.value}</div>
                            </>
                            :
                            <div> </div>
                        }
                    </>
                }
                <div className="key">Autor</div>
                <div className="value"> {item.metadata.dc_creator?.value} </div>
                <div className="value"> {item.metadata.ncd_departament?.value} </div>
                <div className="value"> {item.metadata.ncd_parentinstitutionname?.value} </div>
            </div>
            <div className="metadata visor pane-r">
                <div>
                    <div>
                        <div className="key">Tema</div>
                        <div className="value">{ item.metadata.dc_subject?.value }</div>
                    </div>
                    <div>
                        <div className="key">Título</div>
                        <div className="value">{ item.metadata.dc_title?.value }</div>
                    </div>
                    <div>
                        <div className="key">Descripción</div>
                        <div className="value">{ item.metadata.dc_description?.value }</div>
                    </div>
                    <div className="thumb">
                        <Image src={item.iipbigthumb}/>
                    </div>
                </div>


                <div>
                    <div>
                        <div className="key">Tipo de microscopio</div>
                        <div className="value">{ item.metadata.dc_subject_microscopetype?.value }</div>
                    </div>

                    <div>
                        <div className="key">Autor</div>
                        <div className="value"> {item.metadata.dc_creator?.value} </div>
                    </div>

                    <div>
                        <div className="key">Nombre de la institución</div>
                        <div className="value">{ item.metadata.ncd_parentinstitutionname?.value }</div>
                    </div>
                </div>

                <div>
                    <div>
                        <div className="key">Entidad</div>
                        <div className="value">{ item.metadata.ncd_institutionname?.value }</div>
                    </div>

                    <div>
                        <div className="key">Departamento</div>
                        <div className="value">{ item.metadata.ncd_departament?.value }</div>
                    </div>

                    <div>
                        <div className="key">Derechos de acceso</div>
                        <div className="value"> {item.metadata.dc_rights_accessrights?.value} </div>
                    </div>
                    <div>
                        <div className="key">Propietario</div>
                        <div className="value"> {item.metadata.dc_rights_holder?.value} </div>
                    </div>
                    <div>
                        <div className="key">Licencia</div>
                        <div className="value">{ item.metadata.dc_rights_license?.value }</div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default PaneFicha;
