import { useEffect, useState } from "react";
import {Image} from "semantic-ui-react";
import { Link } from 'react-router-dom';


import SearchBox from 'components/SearchBox';
import img_macro from 'assets/images/logos/imago_macro_unversidades.svg';

const ItemBigCard = ({item, tag}) => {
    const [turn, setTurn] = useState(true);
    useEffect(() => {
        setTurn(prev => !prev)
    }, [item]);
    return (
        <div className="item-card big" >
            <div className="c-left">
                <div className="tag">{tag}</div>

                <div className={`thumb ${turn ? 'right' : 'left'}`}>
                    <Link to={`item/${item.id}`} className="no-txt">
                        <span>{item.metadata.dc_title.value}</span>
                        <Image src={item.iipbigthumb} alt="Imagen"/>
                    </Link>
                </div>
                <div className="title">{item.metadata.dc_title.value}</div>
            </div>

            <div className="dummy line"></div>
            <div className="dummy"></div>

            <div className="c-right">
                <div className="main-title">Consulta imágenes de alta resolución de macroscopía y microscopía</div>
                <SearchBox />
                <div className="logo">
                    <a href="http://www.redmacro.unam.mx/" target="_blank" rel="noreferrer" className="no-txt">
                        <span>Ir a Macro Universidades</span>
                        <img alt="Macro Universidades" src={img_macro}/>
                    </a>
                </div>
                <div className="texto">
                    <p style={{display: 'none'}}>
                        Bienvenidos al Portal de Microscopía virtual y recursos para estudios de la salud.
                    </p>
                    <p style={{display: 'none'}}>
                        El Portal es una herramienta educativa de acceso libre y gratuito. Aquí podrás
                        explorar imágenes de alta resolución de macroscopía y microscopía como si
                        utilizaras un microscopio físico, pero con  algunas ventajas importantes:
                        observar las imágenes fuera del laboratorio  sin horario ni límite de tiempo;
                        realizar acercamientos a una sección específica del preparado, mantieniendo
                        siempre la referencia del lugar donde está ubicada la estructura que estás
                        observando; y navegar dentro de la imagen localizando información importante que
                        ha seleccionado el equipo de docentes.
                    </p>
                    <p>
                        Este Portal es el resultado de un esfuerzo de colaboración entre las
                        Universidades de la Red de Macrouniversidades de América Latina y el Caribe como
                        complemento para tu aprendizaje y formación práctica.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default ItemBigCard;
