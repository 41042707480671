
const ROUTE_REGEX = /(.{2})(.{2})(.{2})(.*)/;
const CLEAR_REGEX = /[.,]+/g;
const SP_REGEX = /\s+/g;
const DIA_REGEX = /[\u0300-\u036f]/g;


const mdnames = {
    'ncd_categorytype': 'Tipo de colección',
    'dw_type': 'Tipo de recurso digital',
    'dc_publisher_country': 'País de publicación',
    'ncd_parentinnstitutionname': 'Nombre de la Institución',
    'ncd_institutionname': 'Entidad',
    'ncd_departament': 'Departamento',
    'ncd_parentcollectionname': 'Nombre de la colección principal',
    'ncd_collectionName': 'Nombre de la colección',
    'ncd_alternativeName': 'Nombre alternativo de la colección',
    'ncd_maincollectionresponsible': 'Responsables de la colección',
    'ncd_collectionresponsible': 'Responsables de la colección principal',
    'ncd_institutioncode': 'Código o acrónimo de la Institución',
    'ncd_collectioncode': 'Código o acrónimo de la colección',
    'dc_audience_level': 'Nivel educativo',
    'dc_audience': 'Audiencia',
    'dc_description_sponsorship': 'Patrocinio',
    'dc_rights_accessrights': 'Derechos de acceso',
    'dc_rights_holder': 'Propietario de los derechos de autor',
    'dc_rights_license': 'Licencia Creative Commons',
    'dc_provenance': 'Procedencia',
    'dc_provenance_credits': 'Créditos de la colección',
    'ncd_contactInformation': 'Contacto de la colección',
    'dc_subject': 'Tema',
    'dc_subject_microscopetype': 'Tipo de microscopía',
    'dc_subject_microscope': 'Microscopio',
    'dc_subject_techniquetype': 'Tipo de técnica',
    'dc_subject_technique': 'Técnica',
    'dc_creator_preparation': 'Autor de la preservación',
    'dc_description_thickness': 'Espesor del corte',
    'dc_description_orientation': 'Referencia de corte',
    'dc_description_cut': 'Descripción del corte',
    'dc_identifier_guid': 'Identificador único del recurso',
    'dc_identifier_local': 'Identificador local',
    'dc_title': 'Título',
    'dc_description_keyword': 'Palabras clave',
    'dc_type': 'Tipo de recurso',
    'dc_format_extent': 'Extensión',
    'dc_description': 'Descripción de la imagen',
    'dc_identifier_doi': 'Identificador de imagen',
    'dc_creator': 'Autor de la imagen',
    'dc_date_created': 'Fecha de creación de la imagen',
    'dc_description_magnification': 'Aumento',
    'dw_kingdom': 'Reino',
    'dw_phylum': 'Phylum',
    'dw_class': 'Clase',
    'dw_order': 'Orden',
    'dw_family': 'Familia',
    'dw_scientificname': 'Nombre científico del organismo o microorganismo',
    'dw_scientificname_host': 'Nombre científico del hospedero',
    'dw_vernacularname': 'Nombre común del organismo o microorganismo',
    'dw_vernacularame_host': 'Nombre común del hospedero',
    'dc_description_organismbreed': 'Raza del organismo',
    'dc_description_organismsex': 'Sexo del organismo',
    'dc_description_organismage': 'Edad del organismo',
    'dc_creator_diagnosis': 'Autor del diagnóstico',
    'dc_date_diagnosis': 'Fecha de diagnóstico',
    'dc_description_disease': 'Enfermedad',
    'dc_description_diagnosis': 'Diagnóstico',
    'dc_description_injurytype': 'Tipo de lesión',
    'dc_description_etiology': 'Etiología',
    'dw_country': 'País',
    'dw_stateprovince': 'Estado o provincia',
    'dw_county': 'Municipio',
    'dw_locality': 'Localidad',
    'dc_subject_system': 'Sistema',
    'dc_subject_organ': 'Órgano',
    'dc_subject_tissue': 'Tejido',
    'dc_identifier_bibliographiccitation': 'Referencia bibliográfica',
    'dc_date_issued': 'Fecha de publicación',
    'dc_date_available': 'Fecha de disponibilidad',
    'dc_date_modified': 'Fecha de última modificación',
    'dc_description_repository': 'Nombre del repositorio',
    'dc_description_methodofinstruction': 'Método de instrucción',
}

const mdsearch = [
    'dc_title',
    'dc_description_keyword',
    'dw_scientificname',
    'dw_vernacularname',
    'dc_description_disease',
    'dc_description_diagnosis',
    'dc_description_injurytype',
    'dc_subject_organ',
]

const cardfields = [
    'dc_title',
    'dc_description_keyword',
    'dw_scientificname',
    'dw_vernacularname',
    'dc_description_disease',
    'dc_description_diagnosis',
    'dc_description_injurytype',
    'dc_subject_organ',
    'dc_subject',
    'dw_scientificname',
    'dw_vernacularname',
    'dc_subject_microscopetype',
    'dc_subject_techniquetype',
    'dc_subject_technique',
    'dc_creator',
    'ncd_departament',
    'ncd_parentinstitutionname',
    'dc_audience',
    'dc_audience_level',
    'dc_rights_accessrights',
    'dc_rights_holder',
    'ncd_collectionName',
    'ncd_maincollectionresponsible',
    "dc_identifier_other",
]

const fixedValues = {
    'dc_subject': [
        'Embriología',
        'Estudio de enfermedades',
        'Estudio de organismo',
        'Histología',
        'Parasitología',
        'Patología',
        'Tipos de células',
        'Tipos de lesión',
        'Bacteriología',
        'Micología',
    ]
}

const doc2Item = (doc) =>{
    let item = { metadata: { }};
    item.id = doc._id;
    item.source = doc._source.source;
    let entries = Object.entries(doc._source.metadata);
    entries.forEach(([key, val]) => {
        item.metadata[key] = {
            value: val.join(', '),
            name: mdnames[key]
        }
    });
    itemIIP(item);
    return item;
}

const hit2Item = (hit) => {
    let item = { metadata: { }, source: {}};
    item.id = hit._id;
    let entries = Object.entries(hit.fields);


    let key;
    entries.forEach(([k, val]) => {
        if (k.startsWith('metadata')) {
            key = k.replace('metadata.', '');
            item.metadata[key] = {
                value: val.join(', '),
                name: mdnames[key],
            }
        } else if (k.startsWith('source')) {
            key = k.replace('source.', '');
            item.source[key] = val.join(', ');
        }


    })

    itemIIP(item);
    return item;
}

const itemIIP = (item) => {
    let iiproute = item.metadata.dc_identifier_other?.value;
    // if (iiproute?.indexOf('assetstore/') >= 0) {
    //     item.iiproute = iiproute;
    // } else
    if (iiproute?.indexOf('/') >= 0) {
        // item.iiproute = 'assetstore/' + iiproute;
        item.iiproute = iiproute;
    } else {
        item.iiproute = iiproute?.match(ROUTE_REGEX);
        let tail = item.iiproute?.shift();
        item.iiproute?.pop();
        item.iiproute?.push(tail);
        item.iiproute?.unshift('assetstore');
        item.iiproute = item.iiproute?.join('/');
    }
    item.iipis = item.source?.iipImageServer;
    item.iipimg = `${item.iipis}?IIIF=${item.iiproute}/info.json`;
    item.iipthumb = `${item.iipis}?FIF=${item.iiproute}&WID=200&HEI=200&CVT=jpeg`;
    item.iipbigthumb = `${item.iipis}?FIF=${item.iiproute}&WID=500&HEI=500&CVT=jpeg`;
}

const queryJson = ({query, tema, size = 10, from = 0, isSearch = false}) => {
    let q = query ? `((${query}) OR ('* ${query} *') OR (*${query}*))` : '';
    // let f = tema ? `${query ? ' AND ' : ''} (metadata.dc_subject:( ${getTema(tema).split(' ').join(' AND ')}) OR metadata.ncd_collectionName:(${getTema(tema).split(' ').join(' AND ')}) OR metadata.ncd_pollectionName:(${getTema(tema).split(' ').join(' AND ')}))` : '';
    let f = tema ? `${query ? ' AND ' : ''} (metadata.dc_subject:( ${getTema(tema).split(' ').join(' AND ')}))` : '';
//  )` : '';
    return {
        "query": {
            "query_string": {
                "query": q + f,
                "fields": mdsearch.map(m => `metadata.${m}${m === 'dc_title' || m === 'dw_vernacularname'? '^3' : ''}`)
            }
        },
        "highlight": {
            "fields": mdsearch.reduce((o, m) => {o[`metadata.${m}`] = {}; return o}, {}),

        },
        "fields": isSearch ? [ 'source.iipImageServer', ...cardfields.map(m => `metadata.${m}`)] : undefined,
        "_source": false,
        "from": from,
        "size": size
    }

}

const parser = new DOMParser()

const hit2suggestions = (hit) => {
    let entries = hit.highlight ? Object.entries(hit.highlight) : {};
    // let keys = Object.keys(hit.highlight);
    // entries = entries.filter(([k]) => mdsearch.includes(k.replace('metadata.', '')))
    entries = entries.filter(([k, v]) => (v + '').split(' ').length < 4);
    let sugg = entries.map(([k, v]) => parser.parseFromString(v, "text/html").documentElement.textContent);

    return sugg;
}

const clearString = (str, val = '') => {
    return str?.replace(CLEAR_REGEX, val).replace(SP_REGEX, ' ').normalize("NFD").replace(DIA_REGEX, "");
}

const getTema = (tema) => {
    return fixedValues.dc_subject.find(t => clearString(t) === clearString(tema)) || tema;
}

export {clearString, hit2Item, queryJson, cardfields, hit2suggestions, doc2Item, fixedValues, getTema};
