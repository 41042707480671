import {  useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { Loader } from 'semantic-ui-react';

// import {doc2Item} from 'util/Util';
import PaneFicha from 'components/PaneFicha';
import PaneMicroscopio from 'components/PaneMicroscopio';
import PaneZonas from 'components/PaneZonas';
import SearchContext from 'context/SearchContext';
import OSDViewer from 'components/OSDViewer';

const Item = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [lorem, setLorem] = useState(null);
    const {setOpenMenu, openMenu, item, loading, loadItem} = useContext(SearchContext);
    const [vista, setVista] = useState('');
    const [navg, setNavg] = useState(null);

    useEffect(() => {

        if (!loading && id) loadItem(id)
        setOpenMenu(false)
    }, [id]);

    useEffect(()=>  {
        window.scrollTo(0, 0);
    },[]);

    useEffect(()=>  {
        if (item.id) {
            setLorem(item.metadata.dc_description_methodofinstruction?.value);
        }
    }, [item]);

    useEffect(() => {
        if (!loading) setVista('vmicro')
    }, [loading]);

    const hClick = (ev) => {
        setVista(ev.target.value)
    }


    return (
        <main className="item">
            <div className={`overlay ${openMenu ? 'open' : ''}`} onClick={() => setOpenMenu(false)}></div>
            <Link onClick={() => navigate(-1)} className="liga-back ico-f1w"><span>Regresar</span></Link>
            <div className="pane-menu">
                <button
                    className={`pane-item ${vista === 'vmicro' ? 'active' : ''}`}
                    value="vmicro"
                    onClick={hClick}
                >Microscopio</button>
                <button
                    className={`pane-item ${vista === 'vficha' ? 'active' : ''}`}
                    value="vficha"
                    onClick={hClick}
                >Ficha</button>
                <button
                    className={`pane-item ${vista === 'vzonas' ? 'active' : ''}`}
                    value="vzonas"
                    disabled={!lorem}
                    onClick={hClick}
                >Exploración guiada</button>
            </div>
            <PaneMicroscopio item={item} active={vista === 'vmicro'}/>
            <PaneFicha item={item} active={vista === 'vficha'}/>
            <PaneZonas item={item} lorem={lorem} setNavg={setNavg} active={vista === 'vzonas'}/>
            {loading && <div className="loading"> <Loader active inline='centered'/> </div>}
            { item.iipimg && item.iipimg !== '' &&
                <OSDViewer source={item.iipimg} vista={vista} navg={navg} active={vista === 'vmicro' || vista === 'vzonas'}/>
            }

        </main>
    );

};

export default Item;
