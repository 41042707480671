import { Link, useNavigate } from 'react-router-dom';

export default function Error404() {
    const navigate = useNavigate();

    return (
        <main className="acercade">
            <Link onClick={() => navigate(-1)} className="liga-back ico-f1w"><span>Regresar</span></Link>
            <div className="titulo">Acerca de</div>
            <div className="contenido">
                <p>
                    El presente proyecto es producto del análisis colectivo realizado en 2021 por un
                    grupo de especialistas en tecnología y educación que conformó el Grupo de
                    Desarrollo Tecnológico de la Red de Macrouniversidades de América Latina y el
                    Caribe. Este grupo inició con la participación de las siguientes Universidades:
                </p>
                <ul>
                    <li>Universidad de la República de Uruguay (Udelar)</li>
                    <li>Universidad de Buenos Aires (UBA)</li>
                    <li>Universidad Nacional de Córdoba (UNC)</li>
                    <li>Universidad Nacional de La Plata (UNLP)</li>
                    <li>Universidad Veracruzana (UV)</li>
                    <li>Universidad Nacional Autónoma de México (UNAM)</li>
                </ul>
                <p>
                    Más adelante, se integraron la Universidad Nacional de Colombia (UNAL) y la Universidad Nacional de Costa Rica (UNC).
                </p>
                <b>
                    El Objetivo del proyecto es
                </b>
                <p>
                    Construir un portal web con imágenes digitales de microscopía y microscopía que
                    aporten las diferentes universidades participantes, con el propósito de contar
                    con una herramienta para la docencia disponible de forma abierta.
                </p>
                <b>
                    El proyecto tiene las siguientes características:
                </b>
                <ul>
                    <li>
                    Parte de un desarrollo existente de la Universidad de la República, Uruguay,
                        para ampliar su alcance hacia otros temas.
                    </li>

                    <li>
                        Se sustenta en la colaboración con diferentes facultades de las
                        universidades participantes, tanto para compartir como para producir nuevas
                        imágenes en diferentes áreas de conocimiento, donde es necesario observar
                        organismos y microorganismos en el microscopio o imágenes de macroscopía.
                    </li>

                    <li>
                        Como herramienta para la docencia, se organiza en función de las temáticas
                        de diversas asignaturas de carreras del área.
                    </li>

                    <li>
                        Las imágenes pueden ser exploradas simulando un microscopio para encontrar
                        diversas estructuras en los tejidos, sin perder definición.
                    </li>

                    <li>
                        La cantidad de imágenes disponibles irá en aumento a medida que se integren
                        nuevas colecciones de diferentes universidades.
                    </li>

                    <li>
                        El desarrollo se realiza con herramientas libres.
                    </li>
                </ul>
                <p>
                    El Portal de Microscopía virtual y recursos para estudios de la salud es un
                    proyecto interdisciplinario en el que participan especialistas en veterinaria,
                    medicina, farmacia y bioquímica, microbiología y otras disciplinas relacionadas;
                    expertos en cómputo, desarrollo de software y de sitios web; expertos en
                    organización de repositorios y catalogación de recursos; y expertos en el uso
                    educativo de las tecnologías digitales.
                </p>

                <p>
                    Este esfuerzo conjunto es un compromiso de las Universidades con la docencia
                    universitaria y el aprendizaje de los estudiantes de cualquier institución
                    educativa de nivel superior. Son bienvenidas todas las Universidades de la Red
                    que quieran sumarse a este esfuerzo.

                </p>
            </div>
        </main>
    )
}
