
import {SearchProvider} from 'context/SearchContext';
import Router from 'routes/Router';

// import { datadogRum } from '@datadog/browser-rum';
//
//
// datadogRum.init({
//     applicationId: 'b2c1c9ae-0175-4ddb-b494-f741ead4b320',
//     clientToken: 'pub2d5250e725b2f42d5732cbc4f90bca43',
//     site: 'datadoghq.com',
//     service: 'microscopio',
//     env: 'prod',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     version: '1.0.0', 
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 100,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
// });



function App() {
    return (
        <SearchProvider>
            <Router/>
        </SearchProvider>
    );
}

export default App;
