import { Link, useNavigate } from 'react-router-dom';

export default function Error404() {
    const navigate = useNavigate();

    return (
        <main className="error">
            <Link onClick={() => navigate(-1)} className="liga-back ico-f1w"><span>Regresar</span></Link>
            <div className="contenido">
                <div className="titulo">Página no encontrada</div>
            </div>
        </main>
    )
}
