import {  useState, useEffect } from "react";
import $ from 'jquery';
// import axios from 'axios';

// import OSDViewer from 'components/OSDViewer';
import 'assets/js/jquery.words'

const re = /^\s/g;
const jsre = /(javascript:)*navegar\((.*),(.*),(.*)\)/;


const clickToSave = () =>  {
    let vp = document.querySelector('.zonas iframe').contentWindow.viewer.viewport;
    let center = vp.getCenter();
    let zoom = vp.getZoom();
    $('.js-value').find('.js-word.active')
        .attr({ 'data-x': center.x, 'data-y': center.y, 'data-zoom': zoom})
        .addClass('word')
        .removeClass('active');
}

const clickToErase = () =>  {
    $('.js-value').find('.js-word.active')
        .removeAttr( 'data-x data-y data-zoom')
        .removeClass('word active');
}

const prepZones = (lorem) => {
    let $lorem = $(lorem);

    $lorem.find('a').each((i, e) => {
        let $e = $(e);
        let ns = e.nextSibling;
        let ws = ''
        if(ns?.nodeType === Node.TEXT_NODE && ns.wholeText.match(re)) {
            ws = ' ';
        }
        // if(ns) console.log('**' + ns?.wholeText, ns.wholeText.match(re));

        let href = $e.attr('href');
        let x, y, zoom;

        let prms = href.match(jsre);
        if (prms) {
            x = prms[2];
            y = prms[3];
            zoom = prms[4];
        } else {
            const urlParams = new URLSearchParams(href.split('?')[1]);
            x = urlParams.get('x');
            y = urlParams.get('y');
            zoom = urlParams.get('z');
        }

        $e.replaceWith( $('<span>', {
            class: 'js-word word',
            'data-x': x,
            'data-y': y,
            'data-zoom': zoom,
            // 'data-zfactor': .5,
            'data-zfactor': 1,
        }).html($e.html() + ws))
    })
    return $lorem//.words();
}

const PaneZonas = ({item, lorem, setNavg, active}) => {
    const [config, setConfig] = useState(false);

    const clickToWord = (e, data) => {
        let $e = $(e.target);
        if ($e.hasClass('js-word')) {
            if (config) {
                $(e.target).toggleClass('active');
            } else {
                let center = {x: e.target.dataset.x * 1, y:  e.target.dataset.y * 1};
                let zoom = e.target.dataset.zoom * 1;
                // let zfactor = e.target.dataset.zfactor * 1;
                if (!isNaN(zoom) && !isNaN(center.x) && !isNaN(center.y)) setNavg({zoom, center});
            }
        }
    }

    const clickToConfig = () => {
        setConfig(prev => !prev)
        $('.js-value').find('.js-word.active').removeClass('active');
    }

    useEffect(() => {
        $('.js-value').html('');
        if (lorem) $('.js-value').append(prepZones(lorem))
    }, [lorem]);

    return (
        <div className={`pane zonas ${active ? 'active' : ''}`}>
            <div className={`${config ? 'config' : ''} desc pane-l`}>
                {/* <div> */}
                {/*     <i className={`icon edit outline word ${config ? 'active' : ''}`} onClick={clickToConfig}></i> */}
                {/*     { config && */}
                {/*     <> */}
                {/*         <i className="icon save outline word" onClick={clickToSave}></i> */}
                {/*         <i className="icon eraser word" onClick={clickToErase}></i> */}
                {/*     </> */}
                {/*     } */}
                {/* </div> */}
                <div className={ `value js-value` } onClick={clickToWord}></div>
            </div>
            {/* {item.iipimg && item.iipimg !== '' && <OSDViewer source={item.iipimg} vista="zonas"/>} */}
        </div>
    );
};

export default PaneZonas;

