import { Link, useNavigate } from 'react-router-dom';

export default function Error404() {
    const navigate = useNavigate();

    return (
        <main className="contacto">
            <Link onClick={() => navigate(-1)} className="liga-back ico-f1w"><span>Regresar</span></Link>
            <div className="titulo">Contacto</div>
            <div className="contenido">
                <form action="mailto:educatic@unam.mx" method="get">
                    <span> Nombre </span>
                    <input type="text" name="name"/>
                    <span> Correo electrónico </span>
                    <input type="text" name="email"/>
                    <span> Asunto </span>
                    <input type="text" name="subject"/>
                    <span> Mensaje </span>
                    <textarea name="body" cols="50" rows="5"></textarea>
                    <button type="submit"> Enviar </button>
                </form>
            </div>
        </main>
    )
}
