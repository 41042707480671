import { createContext, useState, useEffect } from "react";
import axios from 'axios';
import uniqWith from 'lodash/uniqWith';

import {hit2Item, queryJson, hit2suggestions, doc2Item} from 'util/Util';

const TOKEN = process.env.REACT_APP_TOKEN;
const BASE_DOCS = process.env.REACT_APP_DOCUMENTS_EP

// const ITEMS = '_design/views/_view/items';
const ITEMS = '_search';
// const ALL = '_all_docs';

const SearchContext = createContext();

axios.interceptors.request.use(
    req => {
        // console.log('request', req);
        if (!req.headers.Authorization) {
            // req.headers.Authorization = `Bearer ${TOKEN}`;
            req.headers.Authorization = `ApiKey ${TOKEN}`;
        }
        return req;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    res => {
        // console.log('response', res);
        return res;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

const newDate = () => {
    return new Date().toLocaleString('es', {day:"2-digit", year:"numeric", month:"2-digit", hour:"2-digit", minute:"2-digit", second: "2-digit", fractionalSecondDigits: 3});
}

// let loading = false;
// const setLoading = (ldng) => loading = ldng;

const SearchProvider = ({children}) => {

    const [error, setError] = useState();
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [item, setItem] = useState({metadata: {}, iipimg: ''});
    const [temas, setTemas] = useState([]);

    // eslint-disable-next-line
    const [last, setLast] = useState({});


    const loadItem = (id) => {
        console.log(newDate(), 'axios cargando item...');
        setLoading(true)
        axios.get(BASE_DOCS + '_doc/' + id).then((res) => {
            console.log(newDate(), 'axios item cargado', res?.data?._id);
            let item = doc2Item(res.data);
            setItem(item);
            setLoading(false);
            setError(null);
        }).catch(error => {
            setError('Ocurrió un error. Intenta recargar la página.')
            // console.log(error);
        });
    }

    const loadItems = (body) => {
        console.log(newDate(), 'axios cargando items...');
        setLoading(true);
        axios.post(BASE_DOCS + ITEMS, body ).then((res) => {
            console.log(newDate(), 'axios items cargados', res?.data?.hits?.hits?.length);
            setLast(res.data)
            setTotal(res.data.hits.total.value)
            let items = res.data.hits.hits.map(hit2Item);
            setResults(items);
            setLoading(false);
            setError(null);
        }).catch(error => {
            setError('Ocurrió un error. Intenta recargar la página.')
            // console.log(error);
        });
    }


    const search = ({query, tema, size = 10, from = 0}) => {

        let body;

        if (!query && !tema) { // random
            body = {
                "query": {
                    "function_score": {
                        "boost": "5",
                        "random_score": {},
                        "boost_mode": "multiply"
                    }
                },
                "fields": [
                    "metadata.dc_title",
                    "metadata.dc_identifier_other",
                    "source.iipImageServer"
                ],
                "_source": false,
                "from": 0,
                "size": size
            }
       } else {
           query = query?.trim()
           tema = tema?.trim()
           body = queryJson({query, tema, from, size, isSearch: true});
       }

        loadItems(body)
    }


    const autocomplete = async (query, tema) => {
        query = query?.trim()
        tema = tema?.trim()

        let body = queryJson({query, tema, size: 30})
        let res;
        let suggestions;
        try {
            res = await axios.post(BASE_DOCS + ITEMS, body)
            suggestions = uniqWith(res.data.hits.hits.map(hit2suggestions).flat(), (a,b) => a.toLowerCase() === b.toLowerCase()).slice(0, 10);
        } catch (e) {
            /* handle error */
            suggestions = [];
            console.log(e);
        }
        // console.log('sugerencias', suggestions.join(' | '));
        return suggestions;
    }

    const loadTemas = async () => {
        let body = {
            "aggs": {
                "unique_subject": {
                    "terms": {
                        "field": "metadata.dc_subject.keyword",
                        "size": 1500
                    }
                }
            },
            "fields": [
                "metadata.dc_subject"
            ],
            "_source": false,
            "size": 0
        };

        let res;
        try {
            console.log(newDate(), 'axios cargando temas...');
            res = await axios.post(BASE_DOCS + ITEMS, body)
            console.log(newDate(), 'axios temas cargados', res?.data?.aggregations.unique_subject.buckets.length);
            setTemas(res.data.aggregations.unique_subject.buckets);
        } catch (e) {
            /* handle error */
            console.log(e);
        }
        // console.log('sugerencias', suggestions.join(' | '));
    }

    useEffect(() => {
        if (temas.length === 0) {
            loadTemas();
        }
    }, []);


    const props = {results, search, autocomplete, error, total, loading, openFilter, setOpenFilter, openMenu, setOpenMenu, loadItem, item, temas}

    return (
        <SearchContext.Provider value={props} >
            {children}
        </SearchContext.Provider>
    );
}

export {SearchProvider};

export default SearchContext;
