import { useState, useEffect, useContext } from "react";
import { useSearchParams } from 'react-router-dom';

import SearchContext from 'context/SearchContext';
import Intro from 'components/Intro';
import Results from 'components/Results';
import Filter from 'components/Filter';

const pageSize = 15;

export default function Home() {

    const {results, search, loading, total, setOpenMenu, openMenu} = useContext(SearchContext);
    const [searchParams] = useSearchParams();
    const [items, setItems] = useState([]);

    const [tema, setTema] = useState(searchParams.get('tema')?.trim());
    const [query, setQuery] = useState(searchParams.get('query')?.trim());
    const [page, setPage] = useState(0);
    const [load, setLoad] = useState(false);
    const [showFilter, setShowFilter] = useState(query);

    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setTema(searchParams.get('tema')?.trim());
        setQuery(searchParams.get('query')?.trim());
        setOpenMenu(false)
    }, [searchParams]);

    const [showResults, setShowResults] = useState(query || tema);
    useEffect(() => {
        setShowResults(query || tema);
        if (!loading)
            search({query, tema, size: pageSize});
    }, [query, tema]);

    useEffect(() => {
        setShowFilter(query)
    }, [query]);

    useEffect(() => {
        if (showResults) setItems(prev => page ? prev.concat(results) : results);
        else setItems(results);
        setLoad(false);
    }, [results]);

    const handleScroll = () => {
        // console.log(window.innerHeight + document.documentElement.scrollTop, document.documentElement.offsetHeight - 20);
        if ((window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 20)) {
            setLoad(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);



    useEffect(() => {
        let from = pageSize * (page + 1);
        if (showResults && load && !loading && from < total) {
            console.log('Cargamos más', page, from, total);
            search({query, tema, size: pageSize, from});
            setPage(p => p + 1)
        }
    }, [load]);


    return (
        <main className="home">
            {showFilter && <Filter/>}
            <div className={`overlay ${openMenu ? 'open' : ''}`} onClick={() => setOpenMenu(false)}></div>
            {showResults ?  <Results items={items} /> : <Intro items={items} /> }
        </main>
    );
}
