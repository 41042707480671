import { useState, useEffect, useContext } from 'react';
import {Image} from "semantic-ui-react";
import { Link, useParams, useSearchParams } from 'react-router-dom';

import logo from 'assets/images/logos/isotipo_microscopio.svg';
import SearchBox from 'components/SearchBox';
import SearchContext from 'context/SearchContext';
import EMenu from 'components/EMenu';

const Header = () => {
    const [searchParams] = useSearchParams();
    const {id} = useParams();
    const {openMenu, setOpenMenu} = useContext(SearchContext);

    const [tema, setTema] = useState(searchParams.get('tema')?.trim());
    const [query, setQuery] = useState(searchParams.get('query')?.trim());
    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setTema(searchParams.get('tema')?.trim());
        setQuery(searchParams.get('query')?.trim());
    }, [searchParams]);

    const [showBar, setShowBar] = useState(query || tema || id);

    useEffect(() => {
        setShowBar(query || tema || id)
        if (id) (document.getElementById('filter-tema') || {}).value = ''
    }, [query, tema, id]);


    return (
        <div id="header" className={` ${showBar ? 'head-search' : ''}`}>
            <div className="logo">
                <Link to="/" className="no-txt"> <span>Ir a Home</span> <Image src={logo} alt="Logo"/> </Link>
                <div className="title">
                    <div>Microscopía virtual</div>
                    <div>y recursos para estudios de la salud</div>
                </div>
            </div>

            {showBar && <SearchBox /> }

            <button className="btn-menu" onClick={() => setOpenMenu(p => !p)}>
                <span>Explorar</span>
                <span className={openMenu ? 'ico-f1n' : 'ico-f1s'}></span>
            </button>

            {openMenu && <EMenu/> }
        </div>
    );
};

export default Header;
