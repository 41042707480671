import { useState } from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutBasic from 'layouts/LayoutBasic';
import Home from 'pages/Home';
import Item from 'pages/Item';
import Error404 from 'pages/Error404';
import Acercade from 'pages/Acercade';
import Creditos from 'pages/Creditos';
import Contacto from 'pages/Contacto';

const basename = process.env.REACT_APP_BASENAME;

export default function Router() {

    return (
        <BrowserRouter basename={basename}>
            <Routes>
                <Route element={<LayoutBasic />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/item/:id" element={<Item />} />
                    <Route path="/acercade" element={<Acercade/>} />
                    <Route path="/creditos" element={<Creditos/>} />
                    <Route path="/contacto" element={<Contacto/>} />
                    <Route path="*" element={<Error404/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

}
