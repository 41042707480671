import { useEffect } from "react";
import OpenSeadragon from "openseadragon";
import $ from 'jquery';

let viewer;
// const basename = process.env.REACT_APP_BASENAME;

const zoomAndPanTo = ({zoom, center, zfactor = 1}) =>  {
    let vp = viewer.viewport;
    // vp.zoomTo(zoom * zfactor);
    vp.zoomTo(vp.imageToViewportZoom(zoom));
    // vp.panTo((zfactor !== 1 ? vp.imageToViewportCoordinates(center.x, center.y) : center));
    vp.panTo(vp.imageToViewportCoordinates(center.x, center.y));
}

export default function OSDViewer({source, vista, navg, active}) {

    useEffect(() => {
        $('#nav-c').html($('<div>', {id: 'navigator'}))
        $('#openseadragon').html('')
        viewer && viewer.destroy();
        viewer = OpenSeadragon({
            id: "openseadragon",
            prefixUrl: "/osd/osdui/png_icons/",
            // prefixUrl: "osdui/svg_dgtic/",
            // navImages: {
            //     zoomIn: {
            //          REST: 'ico-mas.png',
            //         GROUP: 'ico-mas.png',
            //         HOVER: 'ico-mas.png',
            //         PRESS: 'ico-mas.png',
            //     },
            //     zoomOut: {
            //          REST: 'ico-menos.png',
            //         GROUP: 'ico-menos.png',
            //         HOVER: 'ico-menos.png',
            //         PRESS: 'ico-menos.png',
            //     },
            //     home: {
            //          REST: 'ico-recarga.png',
            //         GROUP: 'ico-recarga.png',
            //         HOVER: 'ico-recarga.png',
            //         PRESS: 'ico-recarga.png',
            //     },
            //     fullpage: {
            //          REST: 'ico-fulls.png',
            //         GROUP: 'ico-fulls.png',
            //         HOVER: 'ico-fulls.png',
            //         PRESS: 'ico-fulls.png',
            //     },
            // },
            animationTime: 0.5,
            blendTime: 0.1,
            // constrainDuringPan: true,
            // maxZoomPixelRatio: 15,
            // visibilityRatio: 1,
            // zoomPerScroll: 1.2,
            tileSources: source,
            showNavigator:  true,
            // wrapHorizontal: true,
            navigationControlAnchor: OpenSeadragon.ControlAnchor.BOTTOM_LEFT,
            // autoHideControls: false,
            defaultZoomLevel: 2,
            // minZoomLevel: -1,
            // minZoomLevel: 0,
            minZoomImageRatio: .1,
            navigatorId: 'navigator',
            navigatorWidth: '250px',
            navigatorHeight: '200px',
            // navigatorPosition: "TOP_LEFT",
            // navigatorBackground: '#B5B5E2',
        })
        // addNavigatorTo(viewer);
    }, [source]);


    useEffect(() => {
        if (navg) {
            zoomAndPanTo(navg);
        }
    }, [navg]);


    return (
        <div className={`pane osd ${vista} ${active ? 'active' : ''}`}>
            {/* <div className="openseadragon pane-r"> */}
            {/*     <iframe src={`${basename ? '/' + basename : ''}/osd?open=${source}&vista=${vista}`} title="Microscopio"></iframe> */}
            {/* </div> */}

            <div id="nav-c" className="pane-lb"> </div>
            <div id="openseadragon" className="pane-r"></div>
        </div>
    );
};
