import { useContext, useState, useEffect } from "react";
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react'

import ItemCard from 'components/ItemCard';
import SearchContext from 'context/SearchContext';
import {getTema} from 'util/Util';

const Results = ({items}) => {
    const {total, loading, openFilter} = useContext(SearchContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [tema, setTema] = useState(searchParams.get('tema')?.trim());
    const [query, setQuery] = useState(searchParams.get('query')?.trim());

    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setTema(searchParams.get('tema')?.trim());
        setQuery(searchParams.get('query')?.trim());
    }, [searchParams]);

    const [showTema, setShowTema] = useState(!query && tema);

    useEffect(() => {
        setShowTema(!query && tema);
    }, [query, tema]);

    return (
        <>
            <div className="ratio">{`Mostrando ${items.length} de ${total}`}</div>
            <div className={`item-results ${openFilter ? 'with-filter' : ''}`}>
                <>
                    <Link onClick={() => navigate(-1)} reloadDocument className="liga-back ico-f1w"><span>Regresar</span></Link>
                    <div className="res-tit">
                        {showTema ?
                            <div>{getTema(tema)}</div>
                            : <div> {`Resultados${query && tema ? ' en ' + getTema(tema) + ':' : ':'}`} </div>
                        }
                        <div>
                            Se {total === 1 ? 'encontró' : 'encontraron'}
                            <b> { total } </b>
                            {total === 1 ? 'imagen' : 'imágenes'}.
                        </div>
                    </div>
                </>
                {items.map((item, i) => <ItemCard key={i} item={item}/>)}
                {loading && <div className="loading"> <Loader active inline='centered'/> </div>}

            </div>
        </>
    );
};

export default Results;
