import { useState } from 'react';
// import OSDViewer from 'components/OSDViewer';

const PaneMicroscopio = ({item, active}) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`pane microscopio ${active ? 'active' : ''}`}>
            <div className={`metadata ${open ? '' : 'closed'} pane-l`}>
                <div className="key">Tema</div>
                <div className="value">{ item.metadata.dc_subject?.value }</div>
                <div className="key">Nombre científico</div>
                <div className="value">{ item.metadata.dw_scientificname?.value }</div>
                <div className="key">Nombre común</div>
                <div className="value">{ item.metadata.dw_vernacularname?.value }</div>
                <div className="key val">{ item.metadata.dc_subject_microscopetype?.value }</div>
                {item.metadata.dc_subject_techniquetype?.value ?
                    <>
                        <div className="key">{item.metadata.dc_subject_techniquetype?.name}</div>
                        <div className="value">{item.metadata.dc_subject_techniquetype?.value}</div>
                    </>
                    :
                    <>
                        {item.metadata.dc_subject_technique?.value ?
                            <>
                                <div className="key">{item.metadata.dc_subject_technique?.name}</div>
                                <div className="value">{item.metadata.dc_subject_technique?.value}</div>
                            </>
                            :
                            <div> </div>
                        }
                    </>
                }
                <div className="key">Autor</div>
                <div className="value"> {item.metadata.dc_creator?.value} </div>
                <div className="value"> {item.metadata.ncd_departament?.value} </div>
                <div className="value"> {item.metadata.ncd_parentinstitutionname?.value} </div>
                <button aria-label="Ver Ficha" className="btn-toggle" onClick={() => setOpen(p => !p)}>
                    <i className={`angle double icon ${open ? 'left' : 'right'}`}/>
                </button>
            </div>
            {/* {item.iipimg && item.iipimg !== '' && <OSDViewer source={item.iipimg}/>} */}
        </div>
    );
};

export default PaneMicroscopio;
