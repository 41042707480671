import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import {fixedValues, clearString} from 'util/Util';
import SearchContext from 'context/SearchContext';


const Filter = () => {
    const [searchParams] = useSearchParams();
    const {setOpenFilter, openFilter, temas} = useContext(SearchContext);
    const [tema, setTema] = useState(searchParams.get('tema')?.trim());

    useEffect(() => {
        // console.log('searchParams', searchParams.get('query'), searchParams.get('tema'));
        setTema(searchParams.get('tema')?.trim());
    }, [searchParams]);

    const selectFilter = (subject) => {
        (document.getElementById('filter-tema') || {}).value = clearString(subject) === clearString(tema) ? '' : subject;
        document.getElementById('search-box')?.submit();
    }

    return (
        <div className={`filter ${openFilter ? '' : 'closed'}`}>
            <div className="filter-title">Temas</div>
            {temas.map((fv, i) => {
                return ( <button key={'filter-item-' + i}
                    className={`menu-item ${clearString(tema) === clearString(fv.key) ? 'ico-f3e selected' : ''}`}
                    onClick={() => selectFilter(fv.key)}
                    data-text={fv.key}
                >
                    <span>{fv.key}</span>
                </button> )
            }) }
            <button className="btn-toggle" onClick={() => setOpenFilter(p => !p)}>
                <i className={`angle double icon ${openFilter ? 'left' : 'right'}`}/>
            </button>
        </div>
    );
};

export default Filter;
