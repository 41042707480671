import { useEffect, useState, useContext } from "react";
import ItemBigCard from 'components/ItemBigCard';
import { Loader } from 'semantic-ui-react'

import SearchContext from 'context/SearchContext';

const Intro = ({items}) => {
    const {loading} = useContext(SearchContext);
    const [idx, setIdx] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // console.log('interval', items.length);
            setIdx(prev => {
                // console.log(prev);
                if (items?.length > 0)
                    // return Math.floor(items.length * Math.random())
                    return ++prev % items.length
                return prev;
            });
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, [items]);

    return (
        <div className="item-intro">
            {items.length > 0 && <ItemBigCard item={items[idx]} tag={'Galería'}/>} 
            {loading && <div className="loading"> <Loader active inline='centered'/> </div>}
        </div>

    );
};

export default Intro;
